import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { page, sectionRow, address, addressLink, instagram } from './contact.module.scss';
import { config } from '../config';
import { IBanner } from '../models/banner.model';
import { ISitePageContext } from '../models/site-page.model';
import { IConsent } from '../models/consent.model';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import SectionRow from '../components/organisms/section-row';
import ContactForm from '../components/organisms/contact-form';

const { formContexts } = config;

interface IContactProps {
    readonly data: {
        bannersCollection: {
            banners: IBanner[];
        } | null;
        allConsent: { edges: { node: IConsent }[] };
    };
    readonly pageContext: ISitePageContext;
}

const Contact: React.FC<IContactProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { bannersCollection, allConsent } = data;

    const banner = bannersCollection?.banners[0];
    const consents = allConsent.edges.map(({ node }) => node);

    return (
        <>
            <SEO title={t('contact.title')} />
            <MainLayout
                className={page}
                bannersData={banner && { banner, titleData: { Tag: 'h1' }, loading: 'eager' }}
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(pageContext)],
                }}
            >
                <SectionRow
                    className={sectionRow}
                    mobileLayout={3}
                    title={t('address.header')}
                    narrowContent={
                        <address className={address}>
                            <p>
                                {t('address.subheader.company')}
                                <br />
                                {t('address.street')}
                                <br />
                                {t('address.zipcode')}
                            </p>
                            <p>
                                {t('address.headquarters.header.uppercase')}
                                <br />
                                <a href="tel:+48225775200" className={addressLink}>
                                    {t('address.headquarters.phone')}
                                </a>
                            </p>
                            <p>
                                {t('address.customerOffice.polish.header.uppercase')}
                                <br />
                                <a href="tel:+48468630888" className={addressLink}>
                                    {t('address.customerOffice.polish.phone')}
                                </a>
                            </p>
                            <p>
                                {t('address.exportDepartment.header.uppercase')}
                                <br />
                                <a
                                    href={`mailto:${t('address.exportDepartment.email')}`}
                                    className={addressLink}
                                >
                                    {t('address.exportDepartment.email')}
                                </a>
                                <br />
                                <a href="tel:+48500060207" className={addressLink}>
                                    {t('address.exportDepartment.phone')}
                                </a>
                            </p>
                            <p>
                                {t('address.businessDevelopment.nip')} <br />
                                {t('address.businessDevelopment.regon')}{' '}
                            </p>
                        </address>
                    }
                    wideContent={
                        <ContactForm
                            title={t('contact.form.title')}
                            consents={consents}
                            formContext={formContexts.contact}
                        />
                    }
                />
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $placeId: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        allConsent(filter: { forms: { elemMatch: { systemName: { eq: "form-contact" } } } }) {
            edges {
                node {
                    ...consentFields
                }
            }
        }
    }
`;

export default Contact;
