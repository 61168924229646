import axios from 'axios';
import { config } from '../config';

interface IContactData {
    email: string;
    content: string;
}

export function postContact(data: IContactData): Promise<any> {
    return axios.post(config.endpoints.contact, data, { withCredentials: true });
}
