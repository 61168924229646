import React from 'react';

import {
    row,
    reverse,
    narrowBox,
    headerBox,
    header,
    wideBox,
    layout2,
    layout3,
} from './section-row.module.scss';

import Title, { ITitle } from '../atoms/title';

interface ISectionRowProps {
    className?: string;
    isReversed?: boolean;
    title?: string;
    titleData?: ITitle;
    wideContent?: React.ReactNode;
    narrowContent?: React.ReactNode;
    mobileLayout?: 1 | 2 | 3;
}

const SectionRow: React.FC<ISectionRowProps> = ({
    className = '',
    isReversed = false,
    title,
    titleData = {},
    wideContent = null,
    narrowContent = null,
    mobileLayout = 1,
}) => {
    return (
        <div
            className={`
                ${row}
                ${className}
                ${isReversed ? reverse : ''}
                ${getLayoutClass(mobileLayout)}
            `}
        >
            {title && (
                <div className={headerBox}>
                    <Title className={header} {...titleData}>
                        {title}
                    </Title>
                </div>
            )}
            {narrowContent && <div className={narrowBox}>{narrowContent}</div>}
            {wideContent && <div className={wideBox}>{wideContent}</div>}
        </div>
    );
};

// const SectionRow: React.FC<ISectionRowProps> = ({
//     className = '',
//     isReversed = false,
//     mobileLayout = 1,
//     title = '',
//     titleData,
//     content = '',
//     secondaryContent = {},
// }) => {
//     const { image, Component, element } = secondaryContent;
//
//     return (
//         <div
//             className={`
//                 ${row}
//                 ${className}
//                 ${isReversed ? reverse : ''}
//                 ${getLayoutClass(mobileLayout)}
//             `}
//         >
//             {title && (
//                 <div className={headerBox}>
//                     <Title className={header} {...titleData} >
//                         {title}
//                     </Title>
//                 </div>
//             )}
//             {content && (
//                 <ReactMarkdown
//                     className={contentBox}
//                     // @ts-ignore
//                     rehypePlugins={[rehypeRaw]}
//                 >
//                     {content}
//                 </ReactMarkdown>
//             )}
//             {(image || Component || element) && (
//                 <div className={imgBox}>
//                     {image && (
//                         <GatsbyImage
//                             alt=""
//                             image={image}
//                             className={img}
//                         />
//                     )}
//                     {Component && <Component />}
//                     {element && element}
//                 </div>
//             )}
//         </div>
//     );
// };

function getLayoutClass(layout: ISectionRowProps['mobileLayout']): string {
    switch (layout) {
        case 2:
            return layout2;
        case 3:
            return layout3;
        default:
            return '';
    }
}

export default SectionRow;
