import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    titleText,
    form,
    inputs,
    successMessage,
    errorText,
    disclaimerText,
} from './contact-form.module.scss';
import UserIcon from '../../assets/images/svg/icon-user.svg';
import EmailIcon from '../../assets/images/svg/icon-email.svg';
import PhoneIcon from '../../assets/images/svg/icon-phone.svg';
import { IConsent } from '../../models/consent.model';
import { postContact } from '../../api/contact';
import { getConsentsFormattedValues } from '../../utils/get-consents-formatted-values';
import { getConsentsInitialValues } from '../../utils/get-consents-initial-values';
import { getConsentsValidation } from '../../utils/get-consents-validation';
import { getFormContextId } from '../../utils/get-form-context-id';
import { config } from '../../config';

import Title, { ITitle } from '../atoms/title';
import Input from '../atoms/form/input';
import Button from '../atoms/button';
import FormikForm from '../hoc/formik-form';
import Error from '../atoms/form/error';
import ConsentsFields from '../molecules/consents-fields';
import Markdown from '../hoc/markdown';

const { statusMap } = config;

interface IContactFormProps {
    className?: string;
    title?: string;
    titleProps?: ITitle;
    consents?: IConsent[];
    formContext?: string;
    disclaimer?: string;
}

const initialValues = {
    name: '',
    email: '',
    phone: '',
    content: '',
};

const getFieldsValidation = (t: ReturnType<typeof useI18next>['t']) => {
    return {
        name: Yup.string().required(t('form.error.required.field')),
        email: Yup.string().required(t('form.error.required.field')).email(t('form.error.email')),
        content: Yup.string().required(t('form.error.required.field')),
    };
};

const ContactForm: React.FC<IContactFormProps> = ({
    className = '',
    title,
    titleProps = {},
    consents = [],
    formContext = '',
    disclaimer,
}) => {
    const { t } = useI18next();
    const contextId = getFormContextId(consents, formContext);

    const handleSubmit = async (
        values: FormikValues,
        helpers: FormikHelpers<any>
    ): Promise<any> => {
        const { name, email, phone, content, consents } = values;

        const data = {
            email: email,
            content: `Name: ${name}, email: ${email}, ${
                phone ? ` phone: ${phone},` : ''
            } content: ${content}`,
            consents: getConsentsFormattedValues(consents, email, contextId),
        };

        helpers.setStatus(statusMap.loading);
        postContact(data)
            .then(() => {
                helpers.setStatus(statusMap.success);
            })
            .catch(() => {
                helpers.setStatus(statusMap.error);
            });
    };

    return (
        <div className={`${container} ${className}`}>
            {title && (
                <Title className={titleText} size="small" {...titleProps}>
                    {title}
                </Title>
            )}
            <Formik
                initialValues={{
                    ...initialValues,
                    consents: getConsentsInitialValues(consents),
                }}
                validationSchema={Yup.object({
                    ...getFieldsValidation(t),
                    consents: getConsentsValidation(consents, t),
                })}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <FormikForm
                        className={form}
                        formik={formik}
                        successMessage={
                            <p className={successMessage}>{t('contact.form.success')}</p>
                        }
                    >
                        <>
                            <div className={inputs}>
                                <Input
                                    name="name"
                                    Icon={UserIcon}
                                    placeholder={t('form.label.name')}
                                />
                                <Input
                                    name="email"
                                    Icon={EmailIcon}
                                    placeholder={t('form.label.email')}
                                />
                                <Input
                                    name="phone"
                                    type="tel"
                                    Icon={PhoneIcon}
                                    placeholder={t('form.label.phone')}
                                />
                                <Input
                                    name="content"
                                    as="textarea"
                                    rows={8}
                                    placeholder={t('form.label.message')}
                                />
                                {disclaimer && (
                                    <Markdown className={disclaimerText}>{disclaimer}</Markdown>
                                )}
                                <ConsentsFields name="consents" consents={consents} />
                                {formik.status === 'error' && (
                                    <Error className={errorText}>{t('form.error.global')}</Error>
                                )}
                            </div>
                            <Button>{t('button.send')}</Button>
                        </>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
